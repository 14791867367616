<template>
  <!-- 产品与解决方案 -->
  <div>
    <pc-header :active1="3"></pc-header>
    <div class="main">
      <div
        class="NeiBanner"
        style="background-image: url('../assets/images/ban2.jpg')"
      >
        <img
          style="width: 100%; height: 900px"
          :src="productBannerList.img_url"
          alt=""
        />
      </div>
      <div class="NeiNav">
        <div class="cmain">
          <ul>
            <li class="active">
              <a href="##">
                <span class="span1">瓶装系列产品</span>
                <!-- <span class="span2">PRODUCT FOR CIVIL</span> -->
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="item" v-if="productList1.length > 0">
        <div class="cmain">
          <div class="NProducts">
            <el-carousel indicator-position="none" height="518px">
              <el-carousel-item v-for="(i, p) in productList1" :key="p">
                <ul>
                  <li
                    v-for="(i1, p1) in i"
                    @click="toProdcutsDetail(i1)"
                    :key="p1"
                  >
                    <a href="##">
                      <dl>
                        <dt>
                          <img
                            :src="i1.img_url"
                            style="width: 545px; height: 382px"
                            alt=""
                          />
                        </dt>
                        <dd>{{ i1.title }}</dd>
                      </dl>
                    </a>
                  </li>
                </ul>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>

      <div class="NeiNav">
        <div class="cmain">
          <ul>
            <li class="active">
              <a href="##">
                <span class="span1">屋顶盒系列产品</span>
                <!-- <span class="span2">PRODUCT FOR CIVIL</span> -->
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="item" v-if="productList2.length > 0">
        <div class="cmain">
          <div class="NProducts">
            <el-carousel indicator-position="none" height="518px">
              <el-carousel-item v-for="(i, p) in productList2" :key="p">
                <ul>
                  <li
                    v-for="(i1, p1) in i"
                    :key="p1"
                    @click="toProdcutsDetail(i1)"
                  >
                    <a href="##">
                      <dl>
                        <dt>
                          <img
                            :src="i1.img_url"
                            style="width: 545px; height: 382px"
                            alt=""
                          />
                        </dt>
                        <dd>{{ i1.title }}</dd>
                      </dl>
                    </a>
                  </li>
                </ul>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>

      <div class="NeiNav">
        <div class="cmain">
          <ul>
            <li class="active">
              <a href="##">
                <span class="span1">袋装产品</span>
                <!-- <span class="span2">PRODUCT FOR CIVIL</span> -->
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="item" v-if="productList3.length > 0">
        <div class="cmain">
          <div class="NProducts">
            <el-carousel indicator-position="none" height="518px">
              <el-carousel-item v-for="(i, p) in productList3" :key="p">
                <ul>
                  <li
                    v-for="(i1, p1) in i"
                    :key="p1"
                    @click="toProdcutsDetail(i1)"
                  >
                    <a href="##">
                      <dl>
                        <dt>
                          <img
                            :src="i1.img_url"
                            style="width: 545px; height: 382px"
                            alt=""
                          />
                        </dt>
                        <dd>{{ i1.title }}</dd>
                      </dl>
                    </a>
                  </li>
                </ul>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>

      <div class="NeiNav">
        <div class="cmain">
          <ul>
            <li class="active">
              <a href="##">
                <span class="span1">果蜜套餐卡</span>
                <!-- <span class="span2">PRODUCT FOR CIVIL</span> -->
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="item">
        <div class="aaaaa">
          <div>果蜜套餐卡系列产品(新鲜定制服务产品)</div>
          <div>
            新橙元为用户打造了果蜜卡系列套餐，为用户提供每月定时配送服务，将最新鲜的橙汁提供给新橙元”鲜果蜜友“，果蜜卡系列包含年卡，十月卡，季卡和单次提货卡。四款果蜜套餐卡，厂家直供更实惠，自家甜橙榨优质鲜橙汁，全年供应，满足家庭每日营养所需
          </div>
        </div>
        <div class="gmk">
          <div class="gmk-box">
            <el-carousel indicator-position="none" height="908px">
              <el-carousel-item v-for="(i, p) in productList4" :key="p">
                <ul>
                  <li
                    v-for="(i1, p1) in i"
                    :key="p1"
                    @click="toProdcutsDetail(i1)"
                  >
                    <a href="##">
                      <dl>
                        <dt>
                          <img
                            :src="i1.img_url"
                            style="width: 838px; height: 297px"
                            alt=""
                          />
                        </dt>
                        <dd>{{ i1.title }}</dd>
                      </dl>
                    </a>
                  </li>
                </ul>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pcHeader from "@/components/pc-header";

export default {
  components: {
    pcHeader,
  },
  data() {
    return {
      productBannerList: "",
      productList1: [],
      productList2: [],
      productList3: [],
      productList4: [],
      params1: {
        type: 1,
        page: 1,
        limit: 100,
      },
      params2: {
        type: 2,
        page: 1,
        limit: 100,
      },
      params3: {
        type: 3,
        page: 1,
        limit: 100,
      },
      params4: {
        type: 4,
        page: 1,
        limit: 100,
      },
    };
  },
  methods: {
    getProductBanner() {
      this.$fetch({
        url: "/api/index/productBanner",
        params: { type: 2 },
      }).then((res) => {
        this.productBannerList = res.data.data;
      });
    },
    getProductList1() {
      this.$fetch({
        url: "/api/index/productList",
        params: this.params1,
      }).then((res) => {
        this.productList1 = this.arrTrans(3, res.data.data);
      });
    },
    getProductList2() {
      this.$fetch({
        url: "/api/index/productList",
        params: this.params2,
      }).then((res) => {
        this.productList2 = this.arrTrans(3, res.data.data);
      });
    },
    getProductList3() {
      this.$fetch({
        url: "/api/index/productList",
        params: this.params3,
      }).then((res) => {
        this.productList3 = this.arrTrans(3, res.data.data);
      });
    },
    getProductList4() {
      this.$fetch({
        url: "/api/index/productList",
        params: this.params4,
      }).then((res) => {
        this.productList4 = this.arrTrans(4, res.data.data);
      });
    },

    // 一维数组转换为二维数组
    arrTrans(num, arr) {
      const iconsArr = []; // 声明数组
      arr.forEach((item, index) => {
        const page = Math.floor(index / num); // 计算该元素为第几个素组内
        if (!iconsArr[page]) {
          // 判断是否存在
          iconsArr[page] = [];
        }
        iconsArr[page].push(item);
      });
      return iconsArr;
    },

    moreProd1() {
      this.params1.page++;
      this.$fetch({
        url: "/api/index/productList",
        params: this.params1,
      }).then((res) => {
        this.productList1 = res.data.data;
        if (this.productList1.length == 0) {
          this.params1.page = 1;
          this.$fetch({
            url: "/api/index/productList",
            params: this.params1,
          }).then((res) => {
            this.$message.warning("没有更多产品了!");
            this.productList1 = res.data.data;
          });
        }
      });
    },
    moreProd2() {
      this.params2.page++;
      this.$fetch({
        url: "/api/index/productList",
        params: this.params2,
      }).then((res) => {
        this.productList2 = res.data.data;
        if (this.productList2.length == 0) {
          this.params2.page = 1;
          this.$fetch({
            url: "/api/index/productList",
            params: this.params2,
          }).then((res) => {
            this.$message.warning("没有更多产品了!");
            this.productList2 = res.data.data;
          });
        }
      });
    },
    moreProd2() {
      this.params3.page++;
      this.$fetch({
        url: "/api/index/productList",
        params: this.params3,
      }).then((res) => {
        this.productList3 = res.data.data;
        if (this.productList3.length == 0) {
          this.params3.page = 1;
          this.$fetch({
            url: "/api/index/productList",
            params: this.params3,
          }).then((res) => {
            this.$message.warning("没有更多产品了!");
            this.productList3 = res.data.data;
          });
        }
      });
    },

    toProdcutsDetail(item) {
      console.log(item);
      this.$router
        .push({ path: "/prodcutsDetail", query: { id: item.id } })
        .catch((err) => {});
    },
  },
  created() {
    this.getProductList1();
    this.getProductList2();
    this.getProductList3();
    this.getProductList4();
    this.getProductBanner();
  },
};
</script>
<style lang="less" scoped>
.item {
  .NProducts {
    ul {
      display: flex;
      justify-content: space-around;
    }
  }
  .aaaaa {
    width: 90%;
    margin: 0 auto;
    font-size: 24px;
    margin-top: 30px;
  }
  .gmk {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 96px;
    .gmk-box {
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 838px;
          height: 297px;
          margin-top: 96px;
          dl {
            dt {
            }
            dd {
              font-size: 24px;
              padding-left: 30px;
            }
          }
        }
      }
    }
  }
}
.el-carousel {
  .el-carousel__container {
    .el-carousel__arrow {
    }
    .el-carousel__arrow--left {
      position: relative;
      top: 100px;
      background: #f48512;
    }
  }
}
/deep/ .el-carousel__arrow {
  top: 80%;
  background: #f48512;
}
</style>